import(/* webpackMode: "eager" */ "/codebuild/output/src1480746833/src/opt-multisite/apps/main-site/app/[lang]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/codebuild/output/src1480746833/src/opt-multisite/libs/ui/shared/theme-provider.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1480746833/src/opt-multisite/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1480746833/src/opt-multisite/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1480746833/src/opt-multisite/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1480746833/src/opt-multisite/node_modules/next/dist/client/script.js");
